import { DataGovernance, PollingMode, getClient } from 'configcat-js';

const getConfigCatCfaToken = (): string => {
  const configCatToken = process.env.REACT_APP_CFA_CONFIGCAT_TOKEN;

  if (!configCatToken) throw Error('ConfigCat CFA token not present');

  return configCatToken;
};

export const configCatCfaClient = getClient(getConfigCatCfaToken(), PollingMode.AutoPoll, {
  dataGovernance: DataGovernance.EuOnly,
});
